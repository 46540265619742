// GLOBAL CONSTANTS
// -----------------------------------

export const STORAGE_KEYS = {
};

//LOCAL
export const URLS = {
    
    // HOMOL
    // API: "https://appservice-roteiros-hlg.azurewebsites.net/api/",
    // REDIRECT: "https://webapp-roteiros-hlg.azurewebsites.net/",

    //HOMOL
    REDIRECT : "https://appsmicrosoft.sesi.org.br/",
    API: "https://dashboardsesi-api-prd.azurewebsites.net/api"

    //LocalHost
    //REDIRECT : "http://localhost:3000/",
    //API: "https://dashboardsesi-api-prd.azurewebsites.net/api"

}

export const ADAL = {
    //TENANT: "cnicombr.onmicrosoft.com",
    //CLIENT_ID: "45cc7d86-63f3-437a-ae1b-494efe0ef992",
    //CLIENT_SECRET: "71b44210-6f1a-49ac-813c-e8458a1b3811"
     TENANT: "sesicni.onmicrosoft.com",
     CLIENT_ID: "a4b5218b-37df-4d87-b0ae-97deb6723c82",
     CLIENT_SECRET: "UeC7Q~jlpE20cgCuhzts4pXj5xFEkN.1mHI02"
}

export default {
    STORAGE_KEYS,
    URLS,
    ADAL
}
