import React, { Component } from 'react';
import {Container} from 'reactstrap';

class PaginaPowerBI extends Component{
    render() {
        return(
            <main className="h-100">
                <Container fluid className="h-100 animated fadeIn">
                    <iframe 
                    style={{width: '100%', height: '100%'}} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiOGI0NzdiM2QtNjhmNi00NjkzLWI5YzUtNTE5YzNlODZlY2IwIiwidCI6IjZkNmJjYzNmLWJkYTEtNGY1NC1hZjFkLTg2ZDRiN2Q0ZTZiOCJ9" 
                    title="POWER BI"
                    >
                    </iframe>
                </Container>
            </main>
        )
    }
}

export default PaginaPowerBI;